import CloseIcon from '@mui/icons-material/Close';
import React, { createContext, useContext } from 'react';
import { SnackbarContextData, SnackbarInput, SnackbarProviderProps } from '../types/snackbar.types';
import styled from 'styled-components';
import { SnackbarKey, SnackbarProvider as SnackbarProviderStack } from 'notistack';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const NotificationContext = createContext<SnackbarContextData>({} as SnackbarContextData);

const CloseIconStyled = styled(CloseIcon)`
  color: white;
`;

const useStyles = makeStyles({
  customSnackbar: {
    top: '4rem !important'
  }
});

export function NotificationProvider({ children }: SnackbarProviderProps) {
  const classes = useStyles();
  const notistackRef = React.createRef<SnackbarProviderStack>();
  const second = 1000;
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  function notify(snackInput: SnackbarInput) {
    const duration = snackInput.keepDisplayMessage ? null : second * 3;
    notistackRef.current?.enqueueSnackbar(snackInput.message, {
      variant: snackInput.severity,
      autoHideDuration: duration
    });
  }

  function notifyError(
    message: string,
    { keepDisplayMessage }: { keepDisplayMessage?: boolean } = {}
  ) {
    notify({ message, severity: 'error', keepDisplayMessage });
  }

  function notifyWarning(
    message: string,
    { keepDisplayMessage }: { keepDisplayMessage?: boolean } = {}
  ) {
    notify({ message, severity: 'warning', keepDisplayMessage });
  }

  function notifySuccess(
    message: string,
    { keepDisplayMessage }: { keepDisplayMessage?: boolean } = {}
  ) {
    notify({ message, severity: 'success', keepDisplayMessage });
  }

  return (
    <NotificationContext.Provider value={{ notify, notifyError, notifyWarning, notifySuccess }}>
      <SnackbarProviderStack
        ref={notistackRef}
        preventDuplicate
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIconStyled />
          </IconButton>
        )}
        classes={{
          containerRoot: classes.customSnackbar
        }}
      >
        {children}
      </SnackbarProviderStack>
    </NotificationContext.Provider>
  );
}

export function useNotification(): SnackbarContextData {
  const context = useContext(NotificationContext);
  return context;
}
