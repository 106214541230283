enum PageRoutes {
  HOME = '/',
  LOGIN = '/login',
  INIT_APP = '/init-app',
  FORGOT_PASSWORD = '/forgot-password',
  REDEFINE_PASSWORD = '/redefine-password',

  //Parametros
  EDIT_PARAMETROS = '/parametros',

  // Diretrizes
  LIST_DIRETRIZES = '/diretrizes/listar',
  CREATE_DIRETRIZ = '/diretrizes/cadastrar',
  EDIT_DIRETRIZ = '/diretrizes/editar/:id',
  VIEW_DIRETRIZ = '/diretrizes/visualizar/:id',

  //Modelos de Mensagem
  LIST_MODELO_MENSAGEM = '/modelos-mensagem/listar',
  CREATE_MODELO_MENSAGEM = '/modelos-mensagem/cadastrar',
  EDIT_MODELO_MENSAGEM = '/modelos-mensagem/editar/:id',

  //Seleção
  LIST_SELECAO = '/selecao/listar',
  CREATE_SELECAO = '/selecao/cadastrar',
  EDIT_SELECAO = '/selecao/editar/:id',
  VIEW_SELECAO = '/selecao/visualizar/:id',

  //ValidacaoSAT
  LIST_VALIDACAO_SAT = '/validacao-sat/listar',
  VIEW_VALIDACAO_SAT = '/validacao-sat/visualizar/:id',
  EDIT_VALIDACAO_SAT = '/validacao-sat/editar/:id',

  /**********************/
  /* Autorregularização */
  /**********************/

  //Contribuíntes AR
  LIST_CONTRIBUINTESAR = '/contribuintes-ar/listar',
  CREATE_NOVO_LOTE_NOTIFICACOES = '/contribuintes-ar/cadastrar',
  EDIT_LOTE_NOTIFICACOES = '/contribuintes-ar/editar/:numeroLote',

  //Gestão de Notificação
  LIST_GESTAO_NOTIFICACAO = '/gestao-notificacao/listar',
  CREATE_NOTIFICACAO_INDIVIDUAL = '/gestao-notificacao/cadastrar',
  VIEW_AUTORREGULARIZACAO = '/gestao-notificacao/visualizar/:id',
  CREATE_RESPOSTA_NOTIFICACAO = '/gestao-notificacao/resposta-notificacao/:id',
  ATRIBUIR_NOTIFICACAO = '/gestao-notificacao/atribuir-notificacao/:id/:auditor',
  EDIT_NOTIFICACAO_INDIVIDUAL = '/gestao-notificacao/editar/:id',

  /************* */
  /*Contribuinte */
  /************* */
  LIST_CONTRIBUINTE_MANUAL = '/contribuinte/manual/listar',
  CREATE_CONTRIBUINTE_MANUAL = '/contribuinte/manual/cadastrar',
  EDIT_CONTRIBUINTE_MANUAL = '/contribuinte/manual/editar/:id',
  VIEW_CONTRIBUINTE_MANUAL = '/contribuinte/manual/visualizar/:id',

  //Estoque
  LIST_ESTOQUE = '/estoque/listar',
  DETALHE_ESTOQUE = '/estoque/detalhe/:id',

  //Notificações Contribuinte
  LIST_NOTIFICACOES = '/notificacoes/listar',
  JUSTIFICAR_NOTIFICACAO = '/notificacoes/justificar-notificacao/:id',
  VIEW_AUTORREGULARIZACAO_CONTRIBUINTE = '/notificacoes/visualizar/:id',

  // Autenticação
  UNAUTHORIZED = '/unauthorized',

  //Sessão Expirada
  SESSION_EXPIRED = '/session-expired',

  // Gerencial
  ARRECADACAO = '/arrecadacao',
  PAINEIS = '/paineis',
  ORDENS = '/ordens',
  LISTAGEM_ORDENS_SERVICO_BY_SERVIDOR = '/ordens/servidor/:servidor_statusOrdem',

  // LogOut
  LOGOUT = '/logout'
}

export default PageRoutes;
