import styled from 'styled-components';

export const Container = styled.div`
  width: 80px;
  max-height: 100%;
  display: flex;
  align-self: start;
  flex-direction: column;
  border-radius: 0 0 4px 0;
  border: 1px solid ${(props) => props.theme.palette.borderColor};
  background: ${(props) => props.theme.palette.background.paper};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99;
  top: 0;
  transition: width 0.3s ease-in-out;
  &.showMenu {
    padding: 0;
    width: 15.3em;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-height: 0;
    overflow-y: hidden;
    border: 0px solid transparent;
    transition: all 0.3s ease-in-out;

    &.showMenu {
      width: 100%;
      max-height: 999px;
      border: 1px solid ${(props) => props.theme.palette.borderColor};
    }
  }

  .icon-menuClosed {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px;
    color: white;
    display: none;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 45px 30px;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  gap: 0.6em;
`;
