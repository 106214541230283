import { gql } from '@apollo/client';
import { IGraphQLOperationList } from '../../../../infra/graphql/interfaces/operation.interface';

const names = ['me'];

const operation = gql`
  query getMe {
    ${names[0]}{
      exp
      user {
        login
        name
        userName
        identificacao
        email
        unidade
        grupo
        isContribuinte
        isCplanf
        isCoordenador
        isFiscal
        isDev
      }
      menu {
        nome
        url
        menuID
        arvoreDeMenu {
          nome
          url
          menuID
          arvoreDeMenu {
            nome
            url
            menuID
          }
        }
      }
    }
  }
`;

export const GET_ME: IGraphQLOperationList = { names, operation };
