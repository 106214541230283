import { gql } from '@apollo/client';
import { IGraphQLOperationList } from '../../../../infra/graphql/interfaces/operation.interface';

const names = ['coordenadoriasOrdens', 'tipoOrdens'];

const operation = gql`
  query{
    ${names[0]}
    ${names[1]}
  }
`;

export const LIST_COORDENADORIAS_TIPO_ORDENS: IGraphQLOperationList = { names, operation };
