import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { OptionSelect } from '../../../_shared/components/form/select-form';
import { useQuery } from '../../../../infra/apollo/hooks/query.hook';
import { ListCoordenadorias } from '../types/list-coordenadorias.type';
import { LIST_COORDENADORIAS_TIPO_ORDENS } from '../queries/list-coordenadorias-tipo-ordens.query';
import { OrdemServico } from '../types/ordem-servico-type';

type FiltrosContextParams = {
  coordenadoriaList: OptionSelect[];
  tipoOrdemList: OptionSelect[];
  coordenadoria: string;
  tipoOrdem: string;
  dataInicial: Date;
  dataFinal: Date;
  hasOrdem: boolean;
  ordensCount: number;
  selectedOrdem?: OrdemServico;
  setCoordenadoria: Dispatch<SetStateAction<string>> | null;
  setTipoOrdem: Dispatch<SetStateAction<string>> | null;
  setDataInicial: Dispatch<SetStateAction<Date>> | null;
  setDataFinal: Dispatch<SetStateAction<Date>> | null;
  setHasOrdem: Dispatch<SetStateAction<boolean>> | null;
  setOrdensCount: Dispatch<SetStateAction<number>> | null;
  setSelectedOrdem: Dispatch<SetStateAction<OrdemServico | undefined>> | null;
};

export const OrdensFiltrosContext = createContext<FiltrosContextParams>({
  coordenadoriaList: [],
  tipoOrdemList: [],
  coordenadoria: 'Todas',
  tipoOrdem: 'Todos',
  dataInicial: new Date(new Date().getFullYear() - 1, 0, 1),
  dataFinal: new Date(),
  hasOrdem: false,
  ordensCount: 0,
  selectedOrdem: undefined,
  setCoordenadoria: null,
  setTipoOrdem: null,
  setDataInicial: null,
  setDataFinal: null,
  setHasOrdem: null,
  setOrdensCount: null,
  setSelectedOrdem: null
});

type ProviderProps = {
  children: ReactNode;
};

export const OrdensFiltrosProvider = ({ children }: ProviderProps) => {
  const [coordenadoriaList, setCoordenadoriaList] = useState<OptionSelect[]>([
    { value: 'Todas', label: 'Todas' }
  ]);
  const [tipoOrdemList, setTipoOrdemList] = useState<OptionSelect[]>([
    { value: 'Todos', label: 'Todos' }
  ]);
  const [coordenadoria, setCoordenadoria] = useState<string>('Todas');
  const [tipoOrdem, setTipoOrdem] = useState<string>('Todos');
  const [dataInicial, setDataInicial] = useState<Date>(
    new Date(new Date().getFullYear() - 1, 0, 1)
  );
  const [dataFinal, setDataFinal] = useState<Date>(new Date());
  const [hasOrdem, setHasOrdem] = useState(false);
  const [ordensCount, setOrdensCount] = useState(0);
  const [selectedOrdem, setSelectedOrdem] = useState<OrdemServico>();

  const { data } = useQuery<ListCoordenadorias>(LIST_COORDENADORIAS_TIPO_ORDENS);

  useEffect(() => {
    if (!data) return;

    if (data.coordenadoriasOrdens) {
      const cOptions: OptionSelect[] = data.coordenadoriasOrdens.map((coordenadoria: string) => ({
        label: coordenadoria,
        value: coordenadoria
      }));

      setCoordenadoriaList([{ value: 'Todas', label: 'Todas' }, ...cOptions]);
    }

    if (data.tipoOrdens) {
      const tipoOrdensOptions: OptionSelect[] = data.tipoOrdens.map((value) => ({
        label: value,
        value: value
      }));

      setTipoOrdemList([{ value: 'Todos', label: 'Todos' }, ...tipoOrdensOptions]);
    }
  }, [data]);

  return (
    <OrdensFiltrosContext.Provider
      value={{
        coordenadoriaList,
        tipoOrdemList,
        coordenadoria,
        setCoordenadoria,
        tipoOrdem,
        setTipoOrdem,
        dataInicial,
        setDataInicial,
        dataFinal,
        setDataFinal,
        hasOrdem,
        setHasOrdem,
        ordensCount,
        setOrdensCount,
        selectedOrdem,
        setSelectedOrdem
      }}
    >
      {children}
    </OrdensFiltrosContext.Provider>
  );
};

export function useOrdensFiltrosContext() {
  const context = useContext(OrdensFiltrosContext);
  return context;
}
