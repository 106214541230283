import { AccountCircle } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Menu, MenuItem } from '@mui/material';
import React, { useCallback } from 'react';
import { useAuth } from '../../../../contexts/auth.context';
import CustomSwitch from '../../custom-switch';
import * as S from '../../../layout/main-layout/header/styles';
import { AuthStatus } from '../../../../constants/enum/authStatus.enum';
import { useHistory } from 'react-router-dom';
import PageRoutes from '../../../../constants/enum/page-routes.enum';

type Props = {
  onOpenMenu: () => void;
};

const Header = ({ onOpenMenu }: Props) => {
  const { status, signOut, user } = useAuth();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSignOut = useCallback(() => {
    history.push(PageRoutes.LOGOUT, { shallow: true });
    signOut();
  }, [history, signOut]);

  const displayProfileLetter = useCallback(() => {
    const username = user?.name;
    return username?.charAt(0) ?? '';
  }, [user]);

  return (
    <>
      {status === AuthStatus.Signed && (
        <S.Container>
          <div className="menu-container">
            <div className="menu-icon">
              <MenuIcon onClick={onOpenMenu} cursor="pointer" id="sideMenuButton" />
            </div>
            <img src="/assets/img/cotin.png" />
          </div>
          <div>{process.env.REACT_APP_DISABLE_DARK_MODE === 'false' && <CustomSwitch />}</div>
          <div className="user-container" onClick={handleClickMenu}>
            <div className="icon">
              <div className="name-display">{displayProfileLetter()}</div>
            </div>
          </div>
          <Menu
            variant="menu"
            MenuListProps={{ variant: 'menu' }}
            elevation={0}
            anchorEl={anchorEl}
            disableAutoFocusItem
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,

                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  borderTopLeftRadius: 4,
                  top: 0,
                  right: 8,
                  width: 8,
                  height: 8,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            className="menu-content"
          >
            <MenuItem>
              <S.UserMenuContent>
                <AccountCircle />
                <div className="user-identification" style={{ lineHeight: '20px' }}>
                  <h3>{user?.name}</h3>
                  <h4>{user?.grupo}</h4>
                  <p style={{ fontWeight: 'bold', fontSize: '0.8em' }}>{user?.unidade}</p>
                  <p style={{ fontWeight: 'bold', fontSize: '0.8em' }}>{user?.email}</p>
                  <p style={{ fontWeight: 'bold', fontSize: '0.8em' }}>Versão - v0.1</p>
                </div>
              </S.UserMenuContent>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOut}>Sair</MenuItem>
          </Menu>
        </S.Container>
      )}
    </>
  );
};

export default Header;
