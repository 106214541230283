import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${(props) => props.theme.palette.borderColor};
  background: ${(props) => props.theme.palette.navBar.main};
  .menu-container {
    display: flex;
    flex: 1;
    .menu-icon {
      color: ${(props) => props.theme.palette.navBar.contrastText};
      padding: 0.7em;
      margin-left: 0.9em;
      @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }

    img {
      width: 10.6em;
      margin-left: 1.4em;
    }
  }

  .user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.6em;
    color: ${(props) => props.theme.palette.text.secondary};
    justify-content: space-between;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      padding: 20px 0;
    }

    .icon .name-display {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      width: 32px;
      height: 32px;
      font-size: 16px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: ${(props) => props.theme.palette.primary.contrastText};
    }

    .perfil-seatings {
      display: flex;
    }

    .icon,
    .username,
    .exit {
      padding: 3px;

      .MuiIconButton-root {
        padding: 0;
      }
    }
  }
`;

export const UserMenuContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
  font-size: 0.85em;
  color: ${(props) => props.theme.palette.text.primary};

  .MuiSvgIcon-root {
    font-size: 2.8em;
  }
  .MuiButton-root {
    color: ${(props) => props.theme.palette.text.primary};
    font-size: 0.6em;
  }

  .user-identification {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;
